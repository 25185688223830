import React from 'react';

const BookIcon: React.FC = () => (
<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none">
  <path
    d="M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z"
    stroke="#141937" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
  <path d="M12 5.48999V20.49" stroke="#141937" strokeWidth="1.5" strokeLinecap="round"
    strokeLinejoin="round" fill='none'/>
  <path d="M7.75 8.48999H5.5" stroke="#141937" strokeWidth="1.5" strokeLinecap="round"
    strokeLinejoin="round" fill='none'/>
  <path d="M8.5 11.49H5.5" stroke="#141937" strokeWidth="1.5" strokeLinecap="round"
    strokeLinejoin="round" fill='none'/>
</svg>
);

export default BookIcon;