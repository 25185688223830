import React from 'react';

const EmailIcon: React.FC = () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="none">
    <path
      d="M24.7917 6.5625H10.2083C6.5902 6.5625 3.64583 9.50688 3.64583 13.125V21.875C3.64583 25.4931 6.5902 28.4375 10.2083 28.4375H24.7917C28.4098 28.4375 31.3542 25.4931 31.3542 21.875V13.125C31.3542 9.50542 28.4098 6.5625 24.7917 6.5625ZM10.2083 8.02083H24.7917C27.0258 8.02083 28.9086 9.47336 29.5984 11.4757L20.3321 17.6531C18.6113 18.7993 16.3902 18.7993 14.6694 17.6531L5.40314 11.4757C6.09148 9.4719 7.97416 8.02083 10.2083 8.02083ZM29.8958 21.875C29.8958 24.6896 27.6062 26.9792 24.7917 26.9792H10.2083C7.39375 26.9792 5.10416 24.6896 5.10416 21.875V13.125C5.10416 13.0944 5.11288 13.0652 5.11288 13.0346L13.86 18.8665C14.9655 19.6029 16.2327 19.9719 17.5 19.9719C18.7673 19.9719 20.0345 19.6044 21.14 18.8665L29.8871 13.0346C29.8871 13.0652 29.8958 13.0929 29.8958 13.125V21.875Z"
      fill="#141937" />
  </svg>
);

export default EmailIcon;