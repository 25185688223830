import React, { useState, useRef } from 'react';
import './WorkCard.css';

type Props = {
  year: string,
  title: string,
  tags: Array<string>,
  imagePath: string,
  href: string,
};

const WorkCard: React.FC<Props> = ({ year, title, tags, imagePath, href }) => {
  const [svgStyle, setSvgStyle] = useState({ top: "-1000px", left: "-1000px" });
  const svgRef = useRef<SVGSVGElement>(null);
  const uniqueId = `${title.replace(/\s+/g, '')}_${year}`

  const onMouseEnter = (event: React.MouseEvent) => {
    if (svgRef.current) {

      const svgHeight = svgRef.current.getBoundingClientRect().height;
      const yOffset = 30;
      const xOffset = 20;

      setSvgStyle({
        top: `${event.clientY - svgHeight - yOffset}px`,
        left: `${event.clientX + xOffset}px`,
      });
    }
  };

  const onMouseMove = (event: React.MouseEvent) => {
    if (svgRef.current) {
      const svgHeight = svgRef.current.getBoundingClientRect().height;
      const yOffset = 30;
      const xOffset = 20;

      setSvgStyle({
        top: `${event.clientY - svgHeight - yOffset}px`,
        left: `${event.clientX + xOffset}px`,
      });
    }
  };

  const onMouseLeave = () => {
    setSvgStyle({ top: "-1000px", left: "-1000px" });
  };

  return (
    <>
      <a
        className="workCard"
        onMouseEnter={onMouseEnter}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        href={href}
      >
        <div>
          <span>{year}</span>
          <h3>{title}</h3>
        </div>
        <div>
          {tags.map((tag, index) => (
            <span key={index}>#{tag}</span>
          ))}
        </div>
      </a>

      <svg id={`hover-svg-${uniqueId}`} width="125" height="125" viewBox="0 0 125 125" fill="none" style={svgStyle} ref={svgRef}>
        <g clipPath={`url(#clip0_81_38_${uniqueId})`}>
          <path
            d="M8.39246 0H116.571C121.5 0.707583 124.309 3.52853 125 8.46284V107.632C124.79 109.725 124.021 111.508 122.694 112.981C120.909 114.965 118.628 115.958 115.85 115.96C81.6498 115.971 47.4484 115.987 13.2461 116.008C13.156 116.008 13.0668 116.035 12.9899 116.087C8.72185 118.872 4.39658 121.563 0.0140766 124.161L0 8.35867C0.731982 3.49944 3.52947 0.713213 8.39246 0ZM3.60079 3.23761C3.64583 3.01239 3.59891 2.89133 3.46002 2.87444C3.38682 2.86318 3.29204 2.89227 3.17568 2.96171C2.01201 3.6524 1.27346 4.62744 0.960023 5.88682C0.910294 6.08757 0.916132 6.2987 0.976914 6.49775C1.10642 6.9238 1.25 7.03735 1.40766 6.8384C1.21997 7.66798 1.12613 8.32113 1.12613 8.79786C1.12613 46.5231 1.12613 84.2371 1.12613 121.94C1.12613 122.118 1.2012 122.159 1.35135 122.064L12.2945 115.11C12.5516 114.947 12.8313 114.865 13.1334 114.865C46.7934 114.865 80.3538 114.865 113.815 114.865C115.964 114.865 117.405 114.762 118.139 114.555C119.592 114.148 120.851 113.342 121.917 112.137C122.938 110.983 123.546 109.637 123.742 108.1C123.83 107.4 123.874 106.3 123.874 104.8C123.874 73.9067 123.874 43.0133 123.874 12.1199C123.874 9.62556 123.825 8.11937 123.727 7.60135C123.429 6.01351 122.706 4.63682 121.557 3.47128C120.827 2.73179 120 2.16122 119.077 1.75957C118.099 1.33727 117.094 1.12613 116.061 1.12613C81.5475 1.12613 47.0336 1.12613 12.5197 1.12613C9.77384 1.12613 8.23386 1.15334 7.89977 1.20777C6.32132 1.46303 4.88833 2.13964 3.60079 3.23761Z"
            fill="#141937" />
          <path
            d="M3.60076 3.23762C2.57786 4.25864 1.84682 5.4589 1.40763 6.8384C1.21994 7.66798 1.1261 8.32114 1.1261 8.79786V121.94C1.1261 122.118 1.20117 122.159 1.35132 122.064L12.2945 115.11C12.5516 114.947 12.8312 114.865 13.1334 114.865H113.815C115.964 114.865 117.405 114.762 118.139 114.555C119.592 114.148 120.851 113.342 121.917 112.137C122.938 110.983 123.546 109.637 123.742 108.1C123.83 107.4 123.874 106.3 123.874 104.8V12.1199C123.874 9.62557 123.825 8.11937 123.727 7.60135C123.429 6.01352 122.705 4.63683 121.557 3.47129C120.827 2.7318 120 2.16123 119.077 1.75958C118.099 1.33728 117.094 1.12613 116.061 1.12613H12.5197C9.77381 1.12613 8.23383 1.15334 7.89975 1.20777C6.32129 1.46303 4.8883 2.13964 3.60076 3.23762Z"
            fill={`url(#pattern0_${uniqueId})`} />
          <path
            d="M3.60077 3.2376C2.57788 4.25862 1.84683 5.45889 1.40764 6.83839C1.24999 7.03734 1.1064 6.92379 0.9769 6.49774C0.916118 6.29869 0.91028 6.08756 0.960009 5.88681C1.27345 4.62743 2.012 3.65239 3.17566 2.9617C3.29203 2.89226 3.38681 2.86317 3.46001 2.87443C3.5989 2.89132 3.64582 3.01238 3.60077 3.2376Z"
            fill="#110028" />
          <path
            d="M118.079 16.3499C117.74 16.8914 117.223 17.3134 116.555 17.5919C115.888 17.8704 115.083 17.9999 114.188 17.973C113.293 17.9461 112.325 17.7633 111.339 17.4351C110.352 17.1069 109.367 16.6397 108.44 16.0602C106.567 14.8898 105.042 13.3329 104.201 11.732C103.359 10.1312 103.271 8.61748 103.954 7.52392C104.292 6.98245 104.81 6.56042 105.478 6.28194C106.145 6.00346 106.949 5.87397 107.844 5.90088C108.739 5.92778 109.708 6.11055 110.694 6.43875C111.68 6.76695 112.665 7.23415 113.593 7.81368C115.466 8.98408 116.991 10.541 117.832 12.1418C118.673 13.7427 118.762 15.2564 118.079 16.3499ZM117.093 15.7295C117.615 14.8936 117.476 13.6922 116.706 12.3894C115.937 11.0866 114.599 9.78919 112.988 8.78259C111.378 7.77601 109.625 7.14268 108.117 7.02194C106.608 6.90119 105.468 7.30293 104.945 8.13876C104.423 8.9746 104.562 10.1761 105.332 11.4789C106.102 12.7816 107.439 14.079 109.05 15.0856C110.661 16.0922 112.413 16.7256 113.922 16.8463C115.43 16.967 116.571 16.5653 117.093 15.7295Z"
            fill="#141937" />
          <path
            d="M109.05 15.0856C112.404 17.1818 116.005 17.47 117.093 15.7295C118.181 13.9889 116.343 10.8787 112.988 8.78258C109.634 6.68646 106.033 6.3982 104.945 8.13874C103.858 9.87928 105.695 12.9895 109.05 15.0856Z"
            fill="#F3F3F3" />
        </g>
        <defs>
          <pattern id={`pattern0_${uniqueId}`} patternContentUnits="objectBoundingBox" width="1" height="1">
            <image id={`svg-image-${uniqueId}`} href={imagePath} width="1" height="1" preserveAspectRatio="xMidYMid slice" />
          </pattern>
          <clipPath id={`clip0_81_38_${uniqueId}`}>
            <rect width="125" height="125" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default WorkCard;
