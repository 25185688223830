import React from 'react';

const GitHubIcon: React.FC = () => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
    <g clipPath="url(#clip0_76_33)">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13.5 0C20.9561 0 27 6.19641 27 13.8415C27 19.9556 23.1363 25.1423 17.7755 26.9743C17.091 27.1106 16.848 26.6784 16.848 26.3098C16.848 25.8535 16.8642 24.3632 16.8642 22.511C16.8642 21.2204 16.4322 20.3781 15.9476 19.9488C18.954 19.6059 22.113 18.4353 22.113 13.119C22.113 11.607 21.5892 10.3732 20.7225 9.40386C20.8629 9.05421 21.3259 7.64634 20.5902 5.74014C20.5902 5.74014 19.4589 5.36925 16.8817 7.15935C15.8031 6.8529 14.6475 6.69871 13.5 6.69331C12.3525 6.69871 11.1983 6.8529 10.121 7.15935C7.5411 5.36925 6.4071 5.74014 6.4071 5.74014C5.67405 7.64634 6.1371 9.05421 6.27615 9.40386C5.4135 10.3732 4.88565 11.607 4.88565 13.119C4.88565 18.4218 8.0379 19.6103 11.0363 19.96C10.6502 20.3056 10.3005 20.9152 10.179 21.8103C9.4095 22.164 7.4547 22.7761 6.2505 20.6607C6.2505 20.6607 5.53635 19.3307 4.18095 19.2335C4.18095 19.2335 2.8647 19.2161 4.08915 20.0747C4.08915 20.0747 4.9734 20.4999 5.58765 22.0997C5.58765 22.0997 6.3801 24.5701 10.1358 23.7331C10.1426 24.8901 10.1547 25.9804 10.1547 26.3098C10.1547 26.6757 9.9063 27.1039 9.23265 26.9756C3.86775 25.1464 0 19.957 0 13.8415C0 6.19641 6.0453 0 13.5 0Z"
        fill="#141937" />
    </g>
    <defs>
      <clipPath id="clip0_76_33">
        <rect width="27" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GitHubIcon;