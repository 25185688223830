import React from 'react';

const SkiIcon: React.FC = () => (
  <svg width="35" height="35" viewBox="0 0 35 35" fill="#800020" >
    <path
      d="M7 30.9167H6.41669V26.25C6.76669 26.25 7 26.0167 7 25.6667C7 25.3167 6.76669 25.0834 6.41669 25.0834V20.4167C6.76669 20.4167 7 20.1834 7 19.8334C7 19.4834 6.76669 19.2501 6.41669 19.2501V13.4168H7C7.35 13.4168 7.58331 13.1834 7.58331 12.8334V8.7502V8.75007V5.83345V5.83331V1.75C7.58331 0.758311 6.825 0 5.83331 0C4.84162 0 4.08331 0.758311 4.08331 1.75V12.8333C4.08331 13.1833 4.31662 13.4166 4.66662 13.4166H5.25V19.25C4.9 19.25 4.66669 19.4833 4.66669 19.8333C4.66669 20.1833 4.9 20.4166 5.25 20.4166V25.0833C4.9 25.0833 4.66669 25.3166 4.66669 25.6666C4.66669 26.0166 4.9 26.25 5.25 26.25V30.9167H4.66669C4.025 30.9167 3.5 31.4417 3.5 32.0834V32.6667C3.5 33.3084 4.025 33.8334 4.66669 33.8334H5.25V34.4167C5.25 34.7667 5.48331 35 5.83331 35C6.18331 35 6.41662 34.7667 6.41662 34.4167V33.8334H6.99993C7.64162 33.8334 8.16662 33.3084 8.16662 32.6667V32.0834C8.16669 31.4417 7.64169 30.9167 7 30.9167ZM5.25 12.25V11.3167L6.41669 10.15V12.25H5.25ZM5.25 8.4L6.41669 7.23331V8.51662L5.25 9.68331V8.4ZM5.83331 1.16669C6.18331 1.16669 6.41662 1.4 6.41662 1.75V5.6L5.25 6.76669V1.75C5.25 1.4 5.48331 1.16669 5.83331 1.16669ZM7 32.6667H4.66669V32.0834H7V32.6667Z"
      fill="#800020" />
    <path
      d="M12.8333 16.9167C11.8416 16.9167 11.0833 17.675 11.0833 18.6667V22.75C11.0833 23.3917 11.6083 23.9167 12.2499 23.9167H13.4166C14.0583 23.9167 14.5833 23.3917 14.5833 22.75V18.6667C14.5833 17.675 13.8249 16.9167 12.8333 16.9167ZM13.4166 22.75H12.2499V18.6667C12.2499 18.3167 12.4833 18.0834 12.8333 18.0834C13.1833 18.0834 13.4166 18.3167 13.4166 18.6667L13.4166 22.75Z"
      fill="#800020" />
    <path
      d="M30.3333 30.9167H29.7499V26.25C30.0999 26.25 30.3333 26.0167 30.3333 25.6667C30.3333 25.3167 30.0999 25.0834 29.7499 25.0834V20.4167C30.0999 20.4167 30.3333 20.1834 30.3333 19.8334C30.3333 19.4834 30.0999 19.2501 29.7499 19.2501V13.4168H30.3333C30.6833 13.4168 30.9166 13.1834 30.9166 12.8334V1.75C30.9166 0.758311 30.1583 0 29.1666 0C28.1749 0 27.4166 0.758311 27.4166 1.75V12.8333C27.4166 13.1833 27.6499 13.4166 27.9999 13.4166H28.5832V19.25C28.2332 19.25 27.9999 19.4833 27.9999 19.8333C27.9999 20.1833 28.2332 20.4166 28.5832 20.4166V25.0833C28.2332 25.0833 27.9999 25.3166 27.9999 25.6666C27.9999 26.0166 28.2332 26.2499 28.5832 26.2499V30.9166H27.9999C27.3583 30.9166 26.8333 31.4416 26.8333 32.0833V32.6666C26.8333 33.3083 27.3583 33.8333 27.9999 33.8333H28.5833V34.4166C28.5833 34.7666 28.8166 34.9999 29.1666 34.9999C29.5166 34.9999 29.7499 34.7666 29.7499 34.4166V33.8333H30.3332C30.9749 33.8333 31.4999 33.3083 31.4999 32.6666V32.0833C31.4999 31.4417 30.9749 30.9167 30.3333 30.9167ZM28.5833 12.25V11.3167L29.7499 10.15V12.25H28.5833ZM28.5833 8.4L29.7499 7.23331V8.51662L28.5833 9.68331V8.4ZM29.1666 1.16669C29.5166 1.16669 29.7499 1.4 29.7499 1.75V5.6L28.5833 6.76669V1.75C28.5833 1.4 28.8166 1.16669 29.1666 1.16669ZM30.3333 32.6667H27.9999V32.0834H30.3333V32.6667Z"
      fill="#800020" />
    <path
      d="M16.3226 3.24064C16.3198 3.20305 16.3172 3.16538 16.3132 3.12812C16.3097 3.09606 16.3052 3.06414 16.3008 3.03215C16.2971 3.00433 16.2935 2.97644 16.2892 2.94882C16.2832 2.91115 16.2761 2.87369 16.2689 2.83623C16.265 2.81572 16.2611 2.79515 16.2567 2.77478C16.2479 2.73328 16.2382 2.69199 16.2279 2.65084C16.224 2.63539 16.22 2.61994 16.2159 2.60456C16.2045 2.56149 16.1924 2.51863 16.1792 2.47611C16.1747 2.46155 16.1699 2.4472 16.1652 2.43271C16.1521 2.3921 16.1387 2.35156 16.124 2.3115C16.1113 2.27657 16.0974 2.24226 16.0836 2.20787C16.0766 2.19078 16.0704 2.17335 16.0632 2.15633C16.035 2.08913 16.0046 2.0231 15.9724 1.95809C15.9634 1.93621 15.9526 1.91529 15.9401 1.89533C15.3558 0.772187 14.1807 0 12.8333 0C10.9083 0 9.33325 1.575 9.33325 3.5V7.75831V11.0833V14.4083V31.5C9.33325 33.425 10.9083 35 12.8333 35C14.7583 35 16.3333 33.425 16.3333 31.5V8.575V5.25V3.5C16.3333 3.43697 16.3313 3.37442 16.3281 3.31222C16.3268 3.28829 16.3243 3.2645 16.3226 3.24064ZM10.4999 8.05L15.1637 3.38625C15.1655 3.42398 15.1666 3.46186 15.1666 3.5V5.01669L10.4999 9.68331V8.05ZM10.4999 11.3167L15.1666 6.65V8.28331L10.4999 12.95V11.3167ZM12.8333 1.16669C13.6082 1.16669 14.2975 1.54991 14.7225 2.13575L10.4999 6.35831V3.5C10.4999 2.21669 11.5499 1.16669 12.8333 1.16669ZM12.8333 33.8333C11.5499 33.8333 10.4999 32.7833 10.4999 31.5V14.6417L15.1666 9.975V31.5C15.1666 32.7833 14.1166 33.8333 12.8333 33.8333Z"
      fill="#800020" />
    <path
      d="M12.8334 32.0833C12.4834 32.0833 12.2501 31.85 12.2501 31.5V26.8333C12.2501 26.4833 12.0168 26.25 11.6668 26.25C11.3168 26.25 11.0835 26.4833 11.0835 26.8333V31.5C11.0835 32.4917 11.8418 33.25 12.8335 33.25C13.1835 33.25 13.4168 33.0167 13.4168 32.6667C13.4168 32.3167 13.1834 32.0833 12.8334 32.0833Z"
      fill="#800020" />
    <path
      d="M22.1667 16.9167C21.1751 16.9167 20.4167 17.675 20.4167 18.6667V22.75C20.4167 23.3917 20.9417 23.9167 21.5834 23.9167H22.7501C23.3917 23.9167 23.9167 23.3917 23.9167 22.75V18.6667C23.9167 17.675 23.1584 16.9167 22.1667 16.9167ZM22.7501 22.75H21.5834V18.6667C21.5834 18.3167 21.8167 18.0834 22.1667 18.0834C22.5167 18.0834 22.75 18.3167 22.75 18.6667L22.7501 22.75Z"
      fill="#800020" />
    <path
      d="M25.659 3.27899C25.6292 2.80793 25.5054 2.36147 25.3059 1.95822C25.2969 1.93628 25.2861 1.91536 25.2736 1.8954C24.6893 0.772187 23.5142 0 22.1667 0C20.2417 0 18.6667 1.575 18.6667 3.5V7.75831V11.0833V14.4083V31.5C18.6667 33.425 20.2417 35 22.1667 35C24.0917 35 25.6667 33.425 25.6667 31.5V8.575V5.25V3.5C25.6667 3.43656 25.6648 3.37354 25.6614 3.31085C25.6608 3.30019 25.6596 3.28966 25.659 3.27899ZM19.8334 8.05L24.4971 3.38625C24.499 3.42398 24.5 3.46186 24.5 3.5V5.01669L19.8333 9.68338V8.05H19.8334ZM19.8334 11.3167L24.5001 6.65V8.28331L19.8334 12.95V11.3167ZM22.1667 1.16669C22.9417 1.16669 23.631 1.54991 24.056 2.13575L19.8334 6.35831V3.5C19.8334 2.21669 20.8834 1.16669 22.1667 1.16669ZM22.1667 33.8333C20.8834 33.8333 19.8334 32.7833 19.8334 31.5V14.6417L24.5001 9.975V31.5C24.5001 32.7833 23.4501 33.8333 22.1667 33.8333Z"
      fill="#800020" />
    <path
      d="M22.1667 32.0833C21.8167 32.0833 21.5834 31.85 21.5834 31.5V26.8333C21.5834 26.4833 21.3501 26.25 21.0001 26.25C20.6501 26.25 20.4167 26.4833 20.4167 26.8333V31.5C20.4167 32.4917 21.1751 33.25 22.1667 33.25C22.5167 33.25 22.7501 33.0167 22.7501 32.6667C22.7501 32.3167 22.5167 32.0833 22.1667 32.0833Z"
      fill="#800020" />
  </svg>
);

export default SkiIcon;