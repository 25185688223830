import React from 'react';

const InstagramIcon: React.FC = () => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
      d="M7.92403 0.165684C5.72639 0.264842 3.8216 0.802138 2.28352 2.33373C0.740063 3.87336 0.209441 5.7857 0.110036 7.96048C0.0482441 9.31788 -0.313084 19.5727 0.734692 22.262C1.44127 24.0764 2.83289 25.4714 4.66381 26.1802C5.51815 26.5125 6.49342 26.7375 7.92403 26.8031C19.8861 27.3445 24.3204 27.0498 26.1902 22.262C26.522 21.4098 26.7504 20.4354 26.8135 19.0083C27.3603 7.01556 26.7249 4.41605 24.6401 2.33373C22.9865 0.684215 21.0414 -0.438643 7.92403 0.165684ZM8.03416 24.3912C6.72444 24.3322 6.01384 24.114 5.53966 23.9304C4.34681 23.4668 3.45083 22.5745 2.99007 21.3886C2.19215 19.3452 2.45679 9.6397 2.52799 8.06925C2.59784 6.53095 2.90948 5.12506 3.99487 4.03968C5.33817 2.6997 7.0737 2.04299 18.8907 2.5763C20.4328 2.64598 21.842 2.95698 22.9301 4.03968C24.2734 5.37965 24.9396 7.12854 24.3969 18.9002C24.3378 20.2067 24.1189 20.9156 23.9349 21.3886C22.7192 24.5041 19.9224 24.9366 8.03416 24.3912ZM19.021 6.33099C19.021 7.21806 19.7424 7.93939 20.633 7.93939C21.5236 7.93939 22.2463 7.21806 22.2463 6.33099C22.2463 5.44393 21.5236 4.72325 20.633 4.72325C19.7424 4.72325 19.021 5.44393 19.021 6.33099ZM6.5646 13.4838C6.5646 17.2839 9.65286 20.3649 13.4625 20.3649C17.2721 20.3649 20.3603 17.2839 20.3603 13.4838C20.3603 9.68358 17.2721 6.60455 13.4625 6.60455C9.65286 6.60455 6.5646 9.68358 6.5646 13.4838ZM8.98523 13.4838C8.98523 11.0182 10.9894 9.01781 13.4625 9.01781C15.9355 9.01781 17.9397 11.0182 17.9397 13.4838C17.9397 15.9506 15.9355 17.9517 13.4625 17.9517C10.9894 17.9517 8.98523 15.9506 8.98523 13.4838Z"
      fill="#141937" />
  </svg>
);

export default InstagramIcon;