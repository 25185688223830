import React from 'react';

const VideoGameIcon: React.FC = () => (
  <svg fill="#141937" height="800px" width="800px" version="1.1" id="Layer_1"
     viewBox="0 0 512 512">
  <g>
    <g>
      <path d="M414.155,200.207c-6.479,0-11.737,5.258-11.737,11.737s5.258,11.737,11.737,11.737c6.479,0,11.737-5.258,11.737-11.737
        S420.634,200.207,414.155,200.207z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M391.855,177.906c-6.479,0-11.737,5.258-11.737,11.737c0,6.479,5.258,11.737,11.737,11.737
        c6.479,0,11.737-5.258,11.737-11.737C403.592,183.165,398.334,177.906,391.855,177.906z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M391.855,222.507c-6.479,0-11.737,5.258-11.737,11.737s5.258,11.737,11.737,11.737c6.479,0,11.737-5.258,11.737-11.737
        S398.334,222.507,391.855,222.507z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M369.555,200.207c-6.479,0-11.737,5.258-11.737,11.737s5.258,11.737,11.737,11.737s11.737-5.258,11.737-11.737
        S376.034,200.207,369.555,200.207z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M510.861,335.499c-3.201-30.048-9.735-62.711-19.42-97.079c-1.757-6.238-8.236-9.874-14.481-8.113
        c-6.238,1.758-9.871,8.241-8.113,14.481c9.327,33.101,15.61,64.458,18.672,93.199c3.387,31.772-2.054,55.944-15.739,69.908
        c-3.703,3.779-8.634,5.805-14.002,5.704c-5.394-0.102-10.36-2.356-13.986-6.346c-43.044-47.371-76.61-71.39-99.766-71.39H167.972
        c-23.156,0-56.721,24.02-99.767,71.393c-3.624,3.988-8.59,6.242-13.985,6.344c-5.338,0.096-10.298-1.926-14.005-5.707
        c-13.683-13.961-19.124-38.132-15.737-69.907c6.711-62.997,27.277-123.955,38.94-154.684c3.522-9.284,10.099-17.263,18.542-22.48
        c1.407-0.874,2.898-1.685,4.427-2.408c19.672-9.291,54.88-21.48,110.892-26.779c18.492-1.749,38.247-2.636,58.718-2.636
        s40.226,0.887,58.717,2.635c56.015,5.302,91.222,17.489,110.892,26.78c1.532,0.723,3.021,1.534,4.453,2.422
        c8.419,5.203,14.995,13.181,18.518,22.466c1.781,4.69,6.239,7.576,10.976,7.576c1.384,0,2.791-0.246,4.162-0.766
        c6.06-2.299,9.109-9.076,6.81-15.137c-4.525-11.924-12.383-22.421-22.428-30.152v-22.127c0-17.154-12.175-32.201-28.948-35.774
        l-52.425-11.183c-12.18-2.604-24.705,1.104-33.502,9.919l-22.078,22.098c-17.535-1.477-36.05-2.23-55.146-2.23
        c-19.096,0-37.611,0.755-55.146,2.231l-22.074-22.098c-8.8-8.815-21.327-12.522-33.501-9.922L92.85,86.92
        C76.074,90.495,63.9,105.541,63.9,122.694v22.129c-10.053,7.739-17.906,18.232-22.428,30.15
        C29.408,206.759,8.128,269.882,1.138,335.498c-4.216,39.534,3.292,69.419,22.312,88.825c8.273,8.445,19.39,12.942,31.215,12.748
        c11.751-0.222,23.02-5.338,30.917-14.032c42.677-46.968,70.544-63.701,82.391-63.701h176.055c11.849,0,39.715,16.735,82.39,63.699
        c7.898,8.696,19.167,13.812,30.918,14.034c0.281,0.005,0.561,0.008,0.84,0.008c11.537-0.001,22.297-4.512,30.372-12.753
        C507.569,404.915,515.077,375.032,510.861,335.499z M349.834,102.241c3.147-3.153,7.625-4.481,11.99-3.548l52.432,11.184
        c6.007,1.281,10.368,6.671,10.368,12.817v9.674c-18.711-7.606-46.028-15.94-83.639-21.27L349.834,102.241z M87.374,122.694
        c0-6.146,4.361-11.536,10.371-12.817l52.434-11.185c4.36-0.933,8.838,0.397,11.99,3.553l8.845,8.853
        c-37.61,5.329-64.928,13.663-83.64,21.269V122.694z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M481.75,206.708l-0.742-2.243c-2.041-6.154-8.684-9.486-14.834-7.445c-6.153,2.041-9.486,8.683-7.445,14.836l0.725,2.192
        c1.626,4.939,6.216,8.07,11.146,8.07c1.216,0,2.454-0.191,3.672-0.593C480.43,219.499,483.778,212.865,481.75,206.708z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M318.006,237.764c-24.593,0-44.601,20.008-44.601,44.601c0,24.593,20.008,44.601,44.601,44.601
        s44.601-20.008,44.601-44.601C362.606,257.772,342.598,237.764,318.006,237.764z M318.006,303.491
        c-11.649,0-21.127-9.478-21.127-21.127s9.479-21.127,21.127-21.127c11.649,0,21.127,9.478,21.127,21.127
        S329.655,303.491,318.006,303.491z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M211.634,270.628h-10.563v-10.563c0-6.481-5.256-11.737-11.737-11.737c-6.481,0-11.737,5.256-11.737,11.737v10.563
        h-10.563c-6.481,0-11.737,5.256-11.737,11.737c0,6.481,5.256,11.737,11.737,11.737h10.563v10.563
        c0,6.482,5.255,11.737,11.737,11.737c6.481,0,11.737-5.256,11.737-11.737v-10.563h10.563c6.481,0,11.737-5.256,11.737-11.737
        C223.371,275.883,218.116,270.628,211.634,270.628z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M124.885,167.342c-24.593,0-44.601,20.008-44.601,44.601c0,24.593,20.008,44.601,44.601,44.601
        c24.593,0,44.601-20.008,44.601-44.601C169.486,187.35,149.478,167.342,124.885,167.342z M124.885,233.069
        c-11.649,0-21.127-9.478-21.127-21.127s9.478-21.127,21.127-21.127s21.127,9.478,21.127,21.127S136.534,233.069,124.885,233.069z"
        />
    </g>
  </g>
  <g>
    <g>
      <path d="M232.525,184.947H214.92c-6.481,0-11.737,5.256-11.737,11.737c0,6.481,5.256,11.737,11.737,11.737h17.605
        c6.482,0,11.737-5.255,11.737-11.737C244.262,190.203,239.006,184.947,232.525,184.947z"/>
    </g>
  </g>
  <g>
    <g>
      <path d="M297.079,184.947h-17.606c-6.481,0-11.737,5.256-11.737,11.737c0,6.481,5.256,11.737,11.737,11.737h17.606
        c6.482,0,11.737-5.255,11.737-11.737C308.816,190.203,303.56,184.947,297.079,184.947z"/>
    </g>
  </g>
  </svg>
);

export default VideoGameIcon;